import _ from 'lodash';
import { Vue, Component } from 'vue-property-decorator';
import { Omit } from 'lodash';
import PricingRow from './PricingRow.vue';
import Affix from '@/components/Affix';

@Component({
  components: {
    PricingRow,
    Affix,
  },

  head() {
    return {
      title: this.$t('pricing.title').toString(),
    };
  },
})
export default class Pricing extends Vue {
  get tableData(): PriceTableItem[] {
    return _.compact([{
      title: this.$t('pricing.functions.base-accounting').toString(),
      is: {
        free: true,
        pro: true,
      },
      child: [{
        title: this.$t('pricing.functions.unlimited-amount').toString(),
        is: {
          free: true,
          pro: true,
        },
      }, {
        title: this.$t('pricing.functions.expenses').toString(),
        is: {
          free: true,
          pro: true,
        },
      }, {
        title: this.$t('pricing.functions.income').toString(),
        is: {
          free: true,
          pro: true,
        },
      }, {
        title: this.$t('pricing.functions.transfer').toString(),
        is: {
          free: true,
          pro: true,
        },
      }, {
        title: this.$t('pricing.functions.clone-record').toString(),
        is: {
          free: true,
          pro: true,
        },
      }],
    }, {
      title: this.$t('pricing.functions.advance-accounting').toString(),
      is: {
        free: false,
        pro: true,
      },
      child: [{
        title: this.$t('pricing.functions.corss-currency').toString(),
        is: {
          free: false,
          pro: true,
        },
        tip: this.$t('pricing.tips.corss-currency').toString(),
      }, {
        title: this.$t('pricing.functions.receivable').toString(),
        is: {
          free: false,
          pro: true,
        },
        tip: this.$t('pricing.tips.receivable').toString(),
      }, {
        title: this.$t('pricing.functions.payable').toString(),
        is: {
          free: false,
          pro: true,
        },
        tip: this.$t('pricing.tips.payable').toString(),
      }, {
        title: this.$t('pricing.functions.extension').toString(),
        is: {
          free: false,
          pro: true,
        },
        tip: this.$t('pricing.tips.extension').toString(),
      }, {
        title: this.$t('pricing.functions.notification').toString(),
        is: {
          free: false,
          pro: true,
        },
        tip: this.$t('pricing.tips.notification').toString(),
      }, {
        title: this.$t('pricing.functions.repeat').toString(),
        is: {
          free: false,
          pro: true,
        },
        tip: this.$t('pricing.tips.repeat').toString(),
      }, {
        title: this.$t('pricing.functions.installment').toString(),
        is: {
          free: false,
          pro: true,
        },
        tip: this.$t('pricing.tips.installment').toString(),
      }, {
        title: this.$t('pricing.functions.refund').toString(),
        is: {
          free: false,
          pro: true,
        },
        tip: this.$t('pricing.tips.refund').toString(),
      }, {
        title: this.$t('pricing.functions.debit').toString(),
        is: {
          free: false,
          pro: true,
        },
        tip: this.$t('pricing.tips.debit').toString(),
      }, {
        title: this.$t('pricing.functions.procedure-fee').toString(),
        is: {
          free: false,
          pro: true,
        },
      }],
    }, {
      title: this.$t('pricing.functions.base-account').toString(),
      is: {
        free: true,
        pro: true,
      },
      child: [{
        title: this.$t('pricing.functions.unlimited-account').toString(),
        is: {
          free: true,
          pro: true,
        },
      }, {
        title: this.$t('pricing.functions.all-currency-summary').toString(),
        is: {
          free: true,
          pro: true,
        },
      }, {
        title: this.$t('pricing.functions.detail-and-statistics').toString(),
        is: {
          free: true,
          pro: true,
        },
      }, {
        title: this.$t('pricing.functions.credit-account').toString(),
        is: {
          free: true,
          pro: true,
        },
      }, {
        title: this.$t('pricing.functions.modify-balance').toString(),
        is: {
          free: true,
          pro: true,
        },
      }, {
        title: this.$t('pricing.functions.pend-fee-notification').toString(),
        is: {
          free: true,
          pro: true,
        },
      }],
    }, {
      title: this.$t('pricing.functions.advance-account').toString(),
      is: {
        free: false,
        pro: true,
      },
      child: [{
        title: this.$t('pricing.functions.reconciliation-mode').toString(),
        is: {
          free: false,
          pro: true,
        },
        tip: this.$t('pricing.tips.reconciliation-mode').toString(),
      }, {
        title: this.$t('pricing.functions.auto-deposit').toString(),
        is: {
          free: false,
          pro: true,
        },
        tip: this.$t('pricing.tips.auto-deposit').toString(),
      }, {
        title: this.$t('pricing.functions.share-credit').toString(),
        is: {
          free: false,
          pro: true,
        },
        tip: this.$t('pricing.tips.share-credit').toString(),
      }],
    }, {
      title: this.$t('pricing.functions.base-report').toString(),
      is: {
        free: true,
        pro: true,
      },
      child: [{
        title: this.$t('pricing.functions.monthly-summary').toString(),
        is: {
          free: true,
          pro: true,
        },
      }, {
        title: this.$t('pricing.functions.monthly-transaction-detail').toString(),
        is: {
          free: true,
          pro: true,
        },
      }, {
        title: this.$t('pricing.functions.monthly-category-distributed').toString(),
        is: {
          free: true,
          pro: true,
        },
      }, {
        title: this.$t('pricing.functions.monthly-transaction-rank').toString(),
        is: {
          free: true,
          pro: true,
        },
      }, {
        title: this.$t('pricing.functions.monthly-name-summary').toString(),
        is: {
          free: true,
          pro: true,
        },
      }, {
        title: this.$t('pricing.functions.monthly-merchant-summary').toString(),
        is: {
          free: true,
          pro: true,
        },
      }, {
        title: this.$t('pricing.functions.monthly-tag-summary').toString(),
        is: {
          free: true,
          pro: true,
        },
      }],
    }, {
      title: this.$t('pricing.functions.advance-report').toString(),
      is: {
        free: false,
        pro: true,
      },
      child: [{
        title: this.$t('pricing.functions.unlimited-report').toString(),
        is: {
          free: false,
          pro: true,
        },
      }, {
        title: this.$t('pricing.functions.free-filter').toString(),
        is: {
          free: false,
          pro: true,
        },
      }, {
        title: this.$t('pricing.functions.report-export-csv').toString(),
        is: {
          free: false,
          pro: true,
        },
      }],
    }, {
      title: this.$t('pricing.functions.project-group').toString(),
      is: {
        free: true,
        pro: true,
      },
    }, {
      title: this.$t('pricing.functions.budget-planning').toString(),
      is: {
        free: true,
        pro: true,
      },
      child: [{
        title: this.$t('pricing.functions.project-budget').toString(),
        is: {
          free: true,
          pro: true,
        },
      }, {
        title: this.$t('pricing.functions.main-category-budget').toString(),
        is: {
          free: true,
          pro: true,
        },
      }, {
        title: this.$t('pricing.functions.sub-category-budget').toString(),
        is: {
          free: true,
          pro: true,
        },
      }],
    }, {
      title: this.$t('pricing.functions.password-protect').toString(),
      is: {
        free: true,
        pro: true,
      },
      tip: this.$t('pricing.tips.password-protect').toString(),
    }, {
      title: this.$t('pricing.functions.cloud-backup-restore').toString(),
      is: {
        free: true,
        pro: true,
      },
      tip: this.$t('pricing.tips.cloud-backup-restore').toString(),
    }, {
      title: this.$t('pricing.functions.category-manager').toString(),
      is: {
        free: true,
        pro: true,
      },
    }, {
      title: this.$t('pricing.functions.classic-dark-theme').toString(),
      is: {
        free: true,
        pro: true,
      },
    }, {
      title: this.$t('pricing.functions.other-theme').toString(),
      is: {
        free: false,
        pro: true,
      },
    }, this.$i18n.locale === 'tw' ? {
      title: this.$t('pricing.functions.einvoice').toString(),
      is: {
        free: false,
        pro: true,
      },
      child: [{
        title: this.$t('pricing.functions.invoice-scanner').toString(),
        is: {
          free: false,
          pro: true,
        },
      }, {
        title: this.$t('pricing.functions.download-einvoice').toString(),
        is: {
          free: false,
          pro: true,
        },
      }, {
        title: this.$t('pricing.functions.auto-lotto').toString(),
        is: {
          free: false,
          pro: true,
        },
      }],
    } : undefined, {
      title: this.$t('pricing.functions.csv-export-import').toString(),
      is: {
        free: false,
        pro: true,
      },
    }, {
      title: this.$t('pricing.functions.search').toString(),
      is: {
        free: false,
        pro: true,
      },
    }, {
      title: this.$t('pricing.functions.batch-modify-delete').toString(),
      is: {
        free: false,
        pro: true,
      },
    }, {
      title: this.$t('pricing.functions.widget').toString(),
      is: {
        free: false,
        pro: true,
      },
    }, {
      title: this.$t('pricing.functions.apple-watch').toString(),
      is: {
        free: false,
        pro: true,
      },
    }, {
      title: this.$t('pricing.functions.email-support').toString(),
      is: {
        free: true,
        pro: true,
      },
    }, {
      title: this.$t('pricing.functions.new-features').toString(),
      is: {
        free: false,
        pro: true,
      },
    }]);
  }
}

export interface PriceTableItem {
  title: string;
  is: {
    free: boolean;
    pro: boolean;
  };
  child?: Array<Omit<PriceTableItem, 'child'>>;
  tip?: string;
}
