import { Vue, Component, Prop } from 'vue-property-decorator';
import Comment from './comment.vue';

@Component({
  components: {
    Comment,
  },
})
export default class PricingRow extends Vue {
  @Prop({ type: Object, required: true })
  data!: object;

  visibleSubRow: boolean = false;

  handleOpenSubRowClick() {
    this.visibleSubRow = !this.visibleSubRow;
  }
}
