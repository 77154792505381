import _ from 'lodash';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { getRect, getScroll } from '../utils/dom';

@Component({
  mounted() {
    const self = this as Affix;
    self.addListener();
  },

  beforeDestroy() {
    const self = this as Affix;
    self.removeListener();
  },

  render(h) {
    const self = this as Affix;

    return (
      <div class='affix-placeholder'
        ref='affixPlaceholder'
        style={self.affixPlaceholderStyle}>
        <div class='affix-content'
          ref='affixContent'
          style={self.affixContentStyle}>
          { self.$slots.default }
        </div>
      </div>
    );
  },
})
export default class Affix extends Vue {
  $refs!: {
    affixPlaceholder: HTMLElement;
    affixContent: HTMLElement;
  };

  @Prop({ default: () => window })
  target!: Window | HTMLElement;

  @Prop(Number)
  offsetTop!: number;

  listened!: boolean;

  affixPlaceholderStyle: PlaceholderStyle = {};

  affixContentStyle: ContentStyle = {};

  addListener() {
    if (this.$isServer) return;
    this.target.addEventListener('scroll', this.updateAffixPosition);
    this.target.addEventListener('resize', this.updateAffixPosition);
    this.listened = true;
  }

  removeListener() {
    if (this.$isServer) return;
    this.target.removeEventListener('scroll', this.updateAffixPosition);
    this.target.removeEventListener('resize', this.updateAffixPosition);
    this.listened = false;
  }

  syncAffixPlaceholderStyle() {
    if (_.isEmpty(this.affixContentStyle)) {
      return;
    }

    this.$refs.affixPlaceholder.style.cssText = '';
    this.$set(this.affixPlaceholderStyle, 'width', `${this.$refs.affixPlaceholder.offsetWidth}px`);
    this.$set(this.affixContentStyle, 'width', `${this.$refs.affixPlaceholder.offsetWidth}px`);
  }

  updateAffixPosition(event: Event) {
    window.requestAnimationFrame(() => {
      const target = window;
      const el = this.$refs.affixPlaceholder;

      const elRect = getRect(el);
      const targetRect = getRect(target);

      const scrollTop = getScroll(target, true);
      const scrollLeft = getScroll(target, false);

      const clientTop = window.document.body.clientTop || 0;
      const clientLeft = window.document.body.clientLeft || 0;

      const elOffset = {
        top: elRect.top - targetRect.top + scrollTop - clientTop,
        left: elRect.left - targetRect.left + scrollLeft - clientLeft,
        width: elRect.width,
        height: elRect.height,
      };

      if (scrollTop > elOffset.top - this.offsetTop) {
        this.$set(this.affixContentStyle, 'position', 'fixed');
        this.$set(this.affixContentStyle, 'top', `${targetRect.top + this.offsetTop}px`);
        this.$set(this.affixContentStyle, 'left', `${targetRect.left + elOffset.left}px`);
        this.$set(this.affixContentStyle, 'width', `${elOffset.width}px`);
        this.$set(this.affixContentStyle, 'zIndex', 3000);
        this.$set(this.affixPlaceholderStyle, 'width', `${elOffset.width}px`);
        this.$set(this.affixPlaceholderStyle, 'height', `${this.$refs.affixPlaceholder.offsetHeight}px`);
      } else {
        this.affixPlaceholderStyle = {};

        if (
          event.type === 'resize' &&
          !_.isEmpty(this.affixContentStyle) &&
          this.affixContentStyle.position === 'fixed' &&
          this.$refs.affixPlaceholder.offsetWidth
        ) {
          this.$set(this.affixContentStyle, 'width', `${this.$refs.affixPlaceholder.offsetWidth}px`);
        } else {
          this.affixContentStyle = {};
        }
      }

      if (event.type === 'resize') {
        this.syncAffixPlaceholderStyle();
      }
    });
  }
}

interface PlaceholderStyle {
  width?: string;
  height?: string;
}

interface ContentStyle {
  position?: string;
  top?: string;
  left?: string;
  width?: string;
}
